// store/mediahubStore.js

import {defineStore} from 'pinia'

export const useMediahubStore = defineStore({
  id: 'mediahub',
  state: () => ({
    salesforceLogin: false,
  }),
  getters: {
    getSalesforceLogin() {
      return this.salesforceLogin
    },
  },
  actions: {
    sendSalesforceRequest() {
      // simulating request
      this.salesforceLogin = true
    },
    logOut() {
      this.salesforceLogin = false
    },
  },
  persist: true,
})
